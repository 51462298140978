// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-comics-js": () => import("./../../../src/pages/comics.js" /* webpackChunkName: "component---src-pages-comics-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-heroine-rises-js": () => import("./../../../src/pages/heroine-rises.js" /* webpackChunkName: "component---src-pages-heroine-rises-js" */),
  "component---src-pages-heroine-rises-list-js": () => import("./../../../src/pages/heroine-rises-list.js" /* webpackChunkName: "component---src-pages-heroine-rises-list-js" */),
  "component---src-pages-heroine-rises-wiki-js": () => import("./../../../src/pages/heroine-rises-wiki.js" /* webpackChunkName: "component---src-pages-heroine-rises-wiki-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-comic-overview-js": () => import("./../../../src/templates/comic-overview.js" /* webpackChunkName: "component---src-templates-comic-overview-js" */),
  "component---src-templates-comic-page-js": () => import("./../../../src/templates/comic-page.js" /* webpackChunkName: "component---src-templates-comic-page-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */)
}

